exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktuelles-tsx": () => import("./../../../src/pages/aktuelles.tsx" /* webpackChunkName: "component---src-pages-aktuelles-tsx" */),
  "component---src-pages-gewerke-tsx": () => import("./../../../src/pages/gewerke.tsx" /* webpackChunkName: "component---src-pages-gewerke-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-leistungen-tsx": () => import("./../../../src/pages/leistungen.tsx" /* webpackChunkName: "component---src-pages-leistungen-tsx" */),
  "component---src-pages-referenzen-tsx": () => import("./../../../src/pages/referenzen.tsx" /* webpackChunkName: "component---src-pages-referenzen-tsx" */),
  "component---src-pages-unternehmen-tsx": () => import("./../../../src/pages/unternehmen.tsx" /* webpackChunkName: "component---src-pages-unternehmen-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "slice---src-components-footer-footer-tsx": () => import("./../../../src/components/footer/footer.tsx" /* webpackChunkName: "slice---src-components-footer-footer-tsx" */),
  "slice---src-components-header-header-tsx": () => import("./../../../src/components/header/header.tsx" /* webpackChunkName: "slice---src-components-header-header-tsx" */)
}

